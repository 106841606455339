/**
 * Grazzee App
 *
 * @format
 * @flow strict-local
 */

import React from 'react'
import {useAuth} from './context/auth-context'
import {FullPageSpinner} from './components/lib'
import {ThemeProvider} from '@material-ui/core/styles'
import theme from './theme'
import './styles/scss/config.scss'
// import Ahoy from './utils/Ahoy'
import TransactionManager from './models/TransactionManager'

const AuthenticatedApp = React.lazy(() =>
  import(/* webpackPrefetch: true */ './authenticated-app'),
)
const Unauthenticated = React.lazy(() => import('./unauthenticated-app'))

function App() {
  const {user} = useAuth()
  // Ahoy.trackall()

  React.useEffect(() => {
    TransactionManager.resetIdempotentKey();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <React.Suspense fallback={<FullPageSpinner />}>
        {user ? <AuthenticatedApp /> : <Unauthenticated />}
      </React.Suspense>
    </ThemeProvider>
  )
}

export {App}
