// AuthClient

import {client, localStorageKey} from './api-client'
import User from '../models/User'

function handleUserResponse({token, user}) {
  // save token
  window.localStorage.setItem(localStorageKey, token)
  return User.fromJson(user)
}

function getUser() {
  const token = getToken()
  if (!token) {
    return Promise.resolve(null)
  }
  return client('me.json').then(data => User.fromJson(data))
}

// type signInData = {
//   user: {
//     email: string,
//     password: string
//   }
// }

function signIn(data) {
  // request to end points is made here
  const user = client('users/sign_in.json', {body: data}).then(
    // save the JWT Token
    handleUserResponse,
  )
  return user
}

function authenticateOTP(number: string): Promise<Object> {
  return client('auth/request.json', {
    method: 'POST',
    body: number,
  })
}

function confirmOTP(data: confirmOTPType): Promise<Object> {
  const user = client('users/sign_in.json', {
    body: data,
  })
    .then(handleUserResponse)
    .catch(e => console.log(e))

  return user
}

// type SignUpData = {
//   user: {
//     email: string,
//     password: string,
//     password_confirmation: string,
//     first_name: string,
//     last_name: string,
//   },
//   organization: {
//     name: string,
//     subdomain: string,
//     address: String,
//   }
// }

function signUp(data) {
  const userInfo = client('users.json', {body: data}).then(response => {
    return response
  })
  return userInfo
}

function forgotPassword(data) {
  const forgotPassword = client('users/password.json', {body: data}).then(
    response => {
      return response
    },
  )
  return forgotPassword
}

function resetPassword(data) {
  const resetPassword = client('users/password.json', {
    body: data,
    method: 'PUT',
  }).then(response => {
    return response
  })
  return resetPassword
}

function getToken() {
  return window.localStorage.getItem(localStorageKey)
}

function isLoggedIn() {
  return Boolean(getToken())
}

export {
  signUp,
  signIn,
  authenticateOTP,
  confirmOTP,
  getToken,
  getUser,
  isLoggedIn,
  forgotPassword,
  resetPassword,
}
export {signOut} from './api-client'
