// import {queryCache} from 'react-query'
import * as auth from './auth-client'
import DataManager from '../models/DataManager'

async function bootstrapAppData() {
  let appData = {user: null, roles: []}
  if (auth.isLoggedIn()) {
    const [user] = await Promise.all([
      auth.getUser(),
      DataManager.loadEntityTypes(),
    ])
    appData = {user}
  } else {
    DataManager.loadEntityTypes()
  }
  return appData
}

export default bootstrapAppData
