/* eslint-disable class-methods-use-this */
/**
 * Grazzee Model : User
 *
 * @format
 * @flow strict-local
 */

import {client} from '../utils/api-client'
import Entity from './Entity'

type UpdateProfileData = {
  firstName?: string,
  lastName?: string,
  email?: string,
  mobile?: string,
}

type confirmOTPType = {
  mobile?: string,
  password?: string,
}

export default class User {
  id: string

  firstName: string

  lastName: string

  mobile: string

  email: string

  avatar: string

  basicProfileComplete: boolean

  onboardingComplete: boolean

  profileComplete: boolean

  balance: number // In cents
  pending: number // In cents

  privacyCardEnabled: boolean

  constructor(id: string) {
    this.id = id
  }

  static fromJson(data: Object) {
    const user = new User(data.id)
    user.firstName = data.first_name
    user.lastName = data.last_name
    user.mobile = data.mobile
    user.email = data.email
    if (data?.avatar_url != null) {
      user.avatar = data?.avatar_url
    }
    user.basicProfileComplete = data.basic_profile_complete
    user.onboardingComplete = data.onboarding_complete
    user.profileComplete = data.profile_complete
    user.balance = parseInt(data.balance, 10)
    user.pending = parseInt(data.reserve, 10)
    user.privacyCardEnabled = data.privacy_card_enabled
    return user
  }

  async updateProfile(data: UpdateProfileData): Promise<User> {
    const postData = {
      user: {
        first_name: data.firstName,
        last_name: data.lastName,
        email: data.email,
        mobile: data.mobile,
      },
    }
    const user = await client('me.json', {method: 'PUT', body: postData})
    this.avatar = user.avatar_url
    this.firstName = user.first_name
    this.lastName = user.last_name
    this.email = user.email
    this.mobile = user.mobile
    return this
  }

  get fullName() {
    return `${this.firstName} ${this.lastName}`
  }

  async joinEntity(data: {
    entity_id: string,
    categories: Array<string>,
    title_id: string,
  }): Promise<boolean> {
    const r = await client(`entities/join.json`, {method: 'POST', body: data})
    return r
  }

  async addPatron(patron_id: string): Promise<Object> {
    const r = await client(`entities/${patron_id}/add-patron.json`, {
      method: 'POST',
      body: {},
    })

    if (r !== undefined && Object.keys(r).length !== 0) {
      return new Entity(r)
    }
    console.log('ERROR ADDING PATRON', r)
    return false
  }

  async getDashboardData(): Promise<Object> {
    return await client(`dashboard.json`)
  }

  async getTransactions(): Promise<Object> {
    return await client('transactions.json')
  }

  async getAttentionTransfers(): Promise<Object> {
    return await client('attention_transfers.json')
  }

  async authenticate(number: string): Promise<Object> {
    const phone_num = number.replace(/\D/g, '').slice(1)

    return await client('auth/request.json', {
      method: 'POST',
      body: {mobile: phone_num},
    })
  }

  async sendReply(transfer_line_item_id: string, content: string): Promise<Object> {
    return await client('transactions/reply.json', {
      method: 'POST',
      body: {
        transfer_line_item_id,
        content
      }
    })
  }

  confirmOTP(data: confirmOTPType): Promise<Object> {
    return client('/users/sign_in.json', {user: data})
      .then(r => {
        // if (r.status === 201 || r.status === 200) {
        //   if (r.headers != null && r.headers['authorization'] != null) {
        //     const authHeader = r.headers['authorization']
        //     if (typeof authHeader === 'string') {
        //       this.setAuthKey(authHeader)
        //     }
        //   }
        // }
        return r
      })
      .catch(e => console.log(e))
  }
}
