import './bootstrap'
import * as Sentry from "@sentry/react";

import React from 'react'
import ReactDOM from 'react-dom'
import {App} from './app'
import {AppProviders} from './context'
import * as serviceWorker from './serviceWorker'

import { getWebInstrumentations, initializeFaro } from '@grafana/faro-web-sdk';
import { TracingInstrumentation } from '@grafana/faro-web-tracing';

// var faro = initializeFaro({
//   url: 'https://faro-collector-prod-us-east-0.grafana.net/collect/7385901b3e0eb44ee4631828a3f15a35',
//   app: {
//     name: 'Grazzee Web',
//     version: '1.0.0',
//     environment: process.env.GRAFANA_ENV
//   },

//   instrumentations: [
//     // Mandatory, overwriting the instrumentations array would cause the default instrumentations to be omitted
//     ...getWebInstrumentations(),

//     // Initialization of the tracing package.
//     // This packages is optional because it increases the bundle size noticeably. Only add it if you want tracing data.
//     new TracingInstrumentation(),
//   ],
// });

Sentry.init({
  dsn: "https://f177b767dc3940ac500f2f36ba3871dc@o4507663733620736.ingest.us.sentry.io/4507722614964224",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 0.1, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  // tracePropagationTargets: ["localhost", /^https:\/\/patagonia\.grazzee\.com/,/^https:\/\/web\.grazzee\.com/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

ReactDOM.render(
  <AppProviders>
    <App />
  </AppProviders>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
