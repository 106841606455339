/**
 * Grazzee Model: Entity
 *
 * @format
 * @flow strict-local
 */

import {client} from '../utils/api-client'

export default class TransactionManager {
  static async performTransaction(
    entity_id: string,
    line_items: Object,
    total: number,
    extra: Object,
  ): Promise<Object> {
    try {
      const r = await client('transactions/give.json', {
        method: 'POST',
        body: {
          entity_id,
          line_items,
          total,
          extra,
          payment_key: TransactionManager.getIdempotentKey(),
        },
      })
      return r.data
    } catch (e) {
      return e
    }
  }

  static async performTransactionUpdate(
    ref: string,
    total: number,
    extra: Object,
  ): Promise<Object> {
    try {
      const r = await client('transactions/give_update.json', {
        method: 'POST',
        body: {
          ref,
          total,
          extra,
        },
      })
      return r.data
    } catch (e) {
      return e
    }
  }

  static getIdempotentKey() {
    const key = localStorage.getItem("idempotent-key");
    if (key != null) {
      return key;
    } else {
      const newKey = generateUUID();
      localStorage.setItem("idempotent-key", newKey);
      return newKey;
    }
  }
  static resetIdempotentKey() {
    localStorage.setItem("idempotent-key", generateUUID());
  }

  static async performACHWithdrawal(data: {
    account_type: string,
    account_number: number,
    account_routing: number,
    total: number,
  }): Promise<Object> {
    try {
      const r = await client('transactions/get.json', {
        method: 'POST',
        body: data,
      })
      return r.data
    } catch (e) {
      return e
    }
  }

  static async getLinkToken(): Promise<Object> {
    try {
      const r = await client(`transactions/link_token.json?platform=web`)
      return r
    } catch (e) {
      return e
    }
  }

  static async getTransactionDetails(ref: string): Promise<Object> {
    try {
      const r = await client(`transactions/${ref}.json`)
      return r
    } catch (e) {
      return e
    }
  }
}

function generateUUID() {
  // Public Domain/MIT
  var d = new Date().getTime(); //Timestamp
  var d2 =
    (typeof performance !== 'undefined' &&
      performance.now &&
      performance.now() * 1000) ||
    0; //Time in microseconds since page-load or 0 if unsupported
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = Math.random() * 16; //random number between 0 and 16
    if (d > 0) {
      //Use timestamp until depleted
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {
      //Use microseconds since page-load if supported
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
}

