/**
 * Grazzee Model: Entity Type
 *
 * @format
 * @flow strict-local
 */

// eslint-disable-next-line import/no-cycle
import Entity from './Entity'
import {client} from '../utils/api-client'

export default class EntityType {
  id: string

  name: string

  slug: string

  mainImageUrl: string

  list: Array<Entity>

  additionalQuestion: string

  format: Object

  approval_line_1: string

  approval_line_2: string

  static list: ?Array<EntityType>

  constructor(data: Object) {
    this.id = data?.id
    this.name = data?.name
    this.slug = data?.slug
    this.mainImageUrl = data?.main_image_url
    this.additionalQuestion = data?.additional_question
    this.approval_line_1 = data.approval_line_1
    this.approval_line_2 = data.approval_line_2
    if (data?.format != null && data.format !== '') {
      if (typeof data.format === 'string') {
        this.format = JSON.parse(data.format)
      } else {
        this.format = data.format
      }
    } else {
      this.format = {}
    }
    this.list = []
  }

  get answerKey(): ?string {
    return Object.keys(this.format)[0]
  }

  get capitalizedAnswerKey(): ?string {
    const {answerKey} = this
    if (answerKey != null && answerKey.length > 0) {
      return answerKey.charAt(0).toUpperCase() + answerKey.slice(1)
    }
    return null
  }

  get placeholder(): string {
    const {format} = this
    let placeholder = ''
    if (format[this.answerKey] != null) {
      placeholder = format[this.answerKey].placeholder ?? ''
    }
    return placeholder
  }

  static async getList(): Promise<Array<EntityType>> {
    if (EntityType.list != null) {
      return EntityType.list
    }
    const r = await client(`entity-types.json`)
    const list = r.map(x => new EntityType(x))
    EntityType.list = list
    return list
  }

  async searchEntities(q: ?string): Promise<Array<Entity>> {
    if (this.list.length > 0) {
      return this.list
    }
    let url = `entity-types/${this.id}/search.json`
    if (q != null) url = `${url}?q=${q}`
    const r = await client(url)
    const list = r.map(e => new Entity(e))
    return list
  }
}
