import {createTheme} from '@material-ui/core/styles'

const theme = createTheme({
  overrides: {
    // Style sheet name ⚛️
    MuiSelect: {
      // Name of the rule
      root: {
        // Some CSS
        // color: 'white',
      },
    },
    MuiButton: {
      label: {
        color: 'black',
      },
    },
    MuiMenuItem: {
      root: {
        // color: 'white',
      },
    },
    // MuiList: {root: {backgroundColor: 'white'}},
    // MuiListItem: {
    //   button: {
    //     backgroundColor: 'white',
    //     '&$selected': {
    //       backgroundColor: 'white',
    //     },
    //     '&$hover': {
    //       backgroundColor: 'white',
    //     },
    //   },
    // },
    MuiPaper: {
      root: {
        backgroundColor: 'white',
        // marginBottom: '20px',
        color: 'black',
      },
    },
    MuiDrawer: {
      paper: {
        backgroundColor: '#262443',
      },
    },
    MuiMenu: {
      paper: {
        backgroundColor: '#262443',
      },
    },
    MuiAvatar: {
      img: {
        height: null,
        width: null,
        maxWidth: '100%',
        objectFit: null,
      },
    },
  },
  palette: {
    primary: {
      light: 'white', // #52516A
      main: 'rgba(108,224,224, 1)',
      dark: 'rgba(108,224,224, 1)',
      contrastText: '#fff',
      grazzee: '#7ec6c5',
      disabled: '#D3D3D3',
      white: '#ffffff',
      grazzeeLight: 'rgba(108,224,224, 1)',
      grazzeeGreen: '#6ce0a6',
      grazzeeGreenDarker: '#39AD73',
      backgroundBlue: '#262443',
      // lighterBlue: '#52516A',
    },
    secondary: {
      light: '#CB7F5F',
      main: '#262443',
      dark: '#9D5030',
      contrastText: '#FFFFFF',
      grazzee: '#7ec6c5',
      disabled: '#D3D3D3',
      white: '#ffffff',
      grazzeeLight: 'rgba(108,224,224, 1)',
      grazzeeGreen: '#6ce0a6',
      grazzeeGreenDarker: '#39AD73',
      backgroundBlue: '#262443',
      // lighterBlue: '#52516A',
    },
    background: {
      // paper: '#52516A',
      default: 'rgba(97,204,204,.1)',
    },
    overrides: {
      primary3Color: '#dff5f5',
      accent1Color: '#dff5f5',
      primary1Color: '#61cccc',
      alternateTextColor: 'rgba(0, 0, 0, 0.87)',
      canvasColor: 'rgba(97,204,204,.1)',
      appBar: {
        color: '#000000',
        textColor: '#61cccc',
      },
    },
  },
})

export default theme
