/**
 * Grazzee Model: Data Manager
 * Only one instance of this will ever be around
 * All data caches to be stored here.
 *
 * @format
 * @flow strict-local
 */
import _ from 'lodash'

import EntityType from './EntityType'

class DataManager {
  entityTypes: Array<EntityType>

  constructor() {
    this.entityTypes = []
  }

  loadEntityTypes() {
    return EntityType.getList().then(res => {
      this.entityTypes = res
    })
  }

  getEntityType(id: string): ?EntityType {
    return _.find(this.entityTypes, (val: EntityType) => val.id === id)
  }
}

export default new DataManager()
