/**
 * Grazzee Model: Employee
 *
 * @flow strict-local
 */

// eslint-disable-next-line import/no-cycle
import User from './User'
import {client} from '../utils/api-client'

export default class Employee {
  id: string

  user: User

  user_id: string

  title: string

  suggestedTip: number

  extraInfo: string

  categoryList: ?Array<string>

  staffAccount: boolean

  messageRequired: boolean

  messageWarning: string

  constructor(data: Object) {
    this.id = data?.id
    if (data?.user instanceof User) {
      this.user = data?.user
    } else {
      this.user = User.fromJson(data?.user)
    }

    this.title = data?.title?.name
    this.extraInfo = data?.title?.extra_info
    this.suggestedTip = data?.title?.suggested_tip
    this.categoryList = data?.category_list
    this.user_id = data.user_id
    this.staffAccount = data.staff_account
    this.messageRequired = data.message_required
    this.messageWarning = data.message_warning
  }

  static async getList(id: string): Promise<Object> {
    const r = await client(`entities/${id}/employees.json`)
    return r.map(e => new Employee(e))
  }
}
