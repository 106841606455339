/** @jsx jsx */
/** @jsxFrag React.Fragment */
import {jsx} from '@emotion/core'
// import * as _ from 'lodash'
import React from 'react'
import bootstrapAppData from '../utils/bootstrap'
import * as authClient from '../utils/auth-client'
import {useAsync} from '../utils/use-async'
import {FullPageSpinner, FullPageErrorFallback} from '../components/lib'

const AuthContext = React.createContext()
AuthContext.displayName = 'AuthContext'

const appDataPromise = bootstrapAppData()

function AuthProvider(props) {
  const {
    data,
    status,
    error,
    isLoading,
    isIdle,
    isError,
    isSuccess,
    run,
    setData,
  } = useAsync()

  React.useLayoutEffect(() => {
    run(appDataPromise)
  }, [run])

  const user = data?.user
  const roles = data?.roles
  // user.organizations[0] not defined
  //  data is saved if successful
  const signIn = React.useCallback(
    form =>
      authClient.signIn(form).then(user => {
        setData({user})
      }),
    [setData],
  )

  //
  const authenticateOTP = React.useCallback(
    mobile => {
      return authClient.authenticateOTP(mobile).then(res => {
        setData({res})
      })
    },
    [setData],
  )

  const confirmOTP = React.useCallback(
    code =>
      authClient.confirmOTP(code).then(user => {
        setData({user})
      }),
    [setData],
  )

  //

  const forgotPassword = React.useCallback(
    form =>
      authClient.forgotPassword(form).then(user => {
        setData(null)
      }),
    [setData],
  )
  const resetPassword = React.useCallback(
    form =>
      authClient.resetPassword(form).then(user => {
        setData(null)
      }),
    [setData],
  )

  const signUp = React.useCallback(
    form =>
      authClient.signUp(form).then(data => {
        const {user} = data
        setData({user})
        signIn({
          user: {
            login: form.user.email,
            password: form.user.password,
          },
        })
      }),
    [setData, signIn],
  )
  const signOut = React.useCallback(() => {
    authClient.signOut()
    setData(null)
  }, [setData])

  const refreshUser = React.useCallback(() => {
    authClient.getUser().then(data => {
      const user = data
      setData({user})
    })
  })

  const value = React.useMemo(
    () => ({
      user,
      signIn,
      authenticateOTP,
      confirmOTP,
      signOut,
      signUp,
      refreshUser,
      forgotPassword,
      resetPassword,
      roles,
    }),
    [
      signIn,
      authenticateOTP,
      confirmOTP,
      signOut,
      signUp,
      refreshUser,
      forgotPassword,
      resetPassword,
      user,
      roles,
    ],
  )

  if (isLoading || isIdle) {
    return <FullPageSpinner />
  }

  if (isError) {
    return <FullPageErrorFallback error={error} />
  }

  if (isSuccess) {
    return <AuthContext.Provider value={value} {...props} />
  }

  throw new Error(`Unhandled status: ${status}`)
}

function useAuth() {
  const context = React.useContext(AuthContext)
  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`)
  }
  return context
}

export {AuthProvider, useAuth}
